.studentRegCourses{
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 5px;
}
.cardImageReg{
    width: 300px;
}

.regCategory{
    position: absolute;
    top: 4px;
    /* left: 60%; */
    right: 4%;
    background-color: #fff;
    padding-left: 18px;
    padding-right: 18px;
    text-align: center;
    border-radius: 25px;
    font-size: 9px;
    font-weight:bold;
}
.searchRegCourses{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 15px;
}

.regCoursesList{
    height: 320px;
    display: flex;
    /* gap: 50px; */
}

.categoryNameText{
    font-size: 10px;
}

.regCoursesContent{
    padding-left: 10px;
}
.cardImageReg{
    width: 300px;
    height: 200px;
}
.coursesCarouselReg{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.liveReport{
    display: flex;
    flex-direction: row;
    gap: 15px;
}
.dashboardCardReg{
    width: 300px;
}

.paginateCarousel{
    margin-top: 15px;
}

.bgOnGoing{
    background-color: #fff;
    width: 100%;
    padding-left:15px;
    padding-bottom: 15px;
    border-radius: 20px;
    margin-top: 4px;
}

.liveImage{
    width: 300px;
}
.onGoing{
    display: flex;
    flex-direction: row;
    gap: 15px;
    
}

.bodyContent1{
    text-align: center;
}