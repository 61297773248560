.superAdminDonutCharts{
    display: flex;
    flex-direction: row;
    gap: 80px;
    /* justify-content: space-between; */
    /* row-gap: 10px; */
}

.singleDonutChart{
    height: 400px !important;
    background-color: #fff;
}