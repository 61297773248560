.courseCategory{
    display: flex;
    flex-direction: column;
}

.footerCategory{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.alignRight .ant-btn{
    width: 100px !important;
}