.App {
    background-color: #2168AE;
    height: 100%;
    display: flex;
    /* flex-direction: row; */
    /* justify-content: space-between; */
  }
  
  .bannerImage{
    height: 500px;
  }
  
  /* .logo{
    display: flex;
    align-items: center;
  } */
  
  .login{
    background-color: #fff;
    min-height: 750px;
    height: 100%;
    /* background-size: cover; */
    /* width: 50%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom-left-radius: 26px;
      border-top-left-radius: 26px;
  }
  
  .formLogo{
    display: flex;
    /* justify-content: center; */
    /* flex-direction: column; */
    align-items: center;
  }
  
  .buttonGroup{
    display: flex;
    flex-direction: row;
    background-color: #f4f4f4;
    border-radius: 24px;
    justify-content: space-between;
    height: 35px;
    align-items: center;
    padding: 8px;
    gap: 6px;
    width: 220px;
  }
  
  /* .studentForm.enable{
    background-color: #2168AE;
    color: #f4f4f4;
  }
  
  .instructorForm.enable{
    background-color: #2168AE;
    color: #f4f4f4;
  
  }
   */
  .underLine{
    text-decoration: underline;
  }
  
  .fields{
    /* margin-left: 10%;
    margin-right: 10%; */
    padding-left: 10%;
    padding-right: 10%;
  }
  
   .inputField .ant-input{
    /* width: 100%; */
   border: 1px solid #e2e2e2 !important;
   outline: none !important;
   height: 50px !important;
  }
  
  
  .rememberMe{
    margin-left: 4px;
  }
  
  .btnColor{
    background-color: #2168AE;
    border-radius: 12px;
  }
  
  .switch{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .switchField{
    margin-right: 10px;
  
  }
  
  .btnLogin{
    width: 100%;
    color: #ffff !important;
    background-color: #4785c2 !important;
    border: none;
    font-size: 16px;
    padding: 4px;
    height: 40px;
  }
  
  .loginWithOthers{
    line-height: 1px;
  }
  .textCenter{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .socialIcons{
    display: flex;
    flex-direction: row;
    justify-content: center;
  
  }
  .facebookIcon{
    /* margin-right: 25px; */
    /* background-color: #f4f4f4; */
    padding: 2px;
    border-radius: 10px;
    height: 20px;
  }
  
  .googleIcon{
    /* background-color: #f4f4f4; */
    padding: 2px;
    border-radius: 10px;
    height: 20px;
  }
  
  .registerLink{
    line-height: 1px;
    cursor: pointer;
  }
  
  .formLabel{
    margin-bottom: 5px;
    color: #333333;
  }
  
  label.ant-form-item-required {
    position: relative;
}

.ant-form-item{
  margin: 0 !important;
}

.ant-row{
  margin: 0 !important;
}


.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun,sans-serif;
  line-height: 1;
  content: "*";
}

.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun,sans-serif;
  line-height: 1;
  content: "";
}

:where(.css-dev-only-do-not-override-v7s41r).ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    /* display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    line-height: 1;
    content: "hello"; */
}

.ant-form-vertical .ant-form-item-label, :where(.css-dev-only-do-not-override-v7s41r).ant-col-24.ant-form-item-label, :where(.css-dev-only-do-not-override-v7s41r).ant-col-xl-24.ant-form-item-label {
  margin: 0;
  padding: 0 0 0 0;
  white-space: initial;
  text-align: start;
}