.ant-btn-primary{
    background-color: #4785c2;
    border: none;
    outline: none;
    /* padding: 10px; */
    /* display: flex;
    align-items: center; */
}

.ant-btn-danger{
    background-color: #d92d20 !important;
    border: none;
    outline: none;
    color: #fff;
}

.ant-btn-default{
    color: black;
    border: none;
    outline: none;
}
.studentFormEnable {
    background-color: #4785c2;
    color: #f4f4f4;
    cursor: pointer;
    border-radius: 12px;
    height: 25px;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 18px;
}

.studentFormEnable:hover{
    background-color: #4785c2 !important;
    color: #f4f4f4 !important;
    cursor: pointer;
    border-radius: 12px;
    height: 25px;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 18px;


}

.instructorFormEnable{
    background-color: #4785c2;
    color: #f4f4f4;
    cursor: pointer;
    border-radius: 12px;
    height: 25px;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 18px;

}
.instructorFormEnable:hover{
    background-color: #4785c2 !important;
    color: #f4f4f4 !important;
    cursor: pointer;
    border-radius: 8px;
    border-radius: 12px;
    height: 25px;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 18px;


}
.studentForm{
    color: #fff;
    margin-right: 4px;
    color: black;
    border-radius: 8px;
    padding: 4px;
    cursor: pointer;
    background-color: #f4f4f4 !important;
    border-radius: 12px;
    height: 25px;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 18px;

  }

  .studentForm:hover{
    color: black !important;
    background-color: #f4f4f4 !important;
    border-radius: 12px;
    height: 25px;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 18px;

  }

.instructorForm{
    color: #fff;
    margin-right: 4px;
    color: black;
    border-radius: 8px;
    padding: 4px;
    cursor: pointer;
    background-color: #f4f4f4 !important;
    border-radius: 12px;
    height: 25px;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 18px;


}

.instructorForm:hover{
    color: black !important;
    background-color: #f4f4f4 !important;
    border-radius: 12px;
    height: 25px;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 18px;

  }