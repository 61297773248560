.instructorCancelClassContainer{
    display: flex;
    flex-direction: column;
}

.submitCancelClass{
    margin-top: 8px;
}

.instructorCancelClassTimes{
    display: flex;
    flex-direction: row;
    gap: 15px;
}

/* .classCancelledDate.react-datepicker__input-container input{
    height: 28px !important;
} */