.addInstructor{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.names{
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.contact{
    display: flex;
    flex-direction: row;
    gap: 15px;
}
.selectDropdownsInstructor{
    display: flex;
    flex-direction: row;
    gap: 15px;
    flex-wrap: wrap;
    width: 100%;
}


/* Optional: For mobile responsiveness */
@media (max-width: 768px) {
    .instructorCourseSelectionType,
    .instructorCourseSelectionTypeGender {
      width: 100%;
    }
  }
/* .InstructorInput{
    width: 230px !important;
}
.intructorSelectGender .ant-select{
    width: 230px !important;
}

 .intructorSelect .ant-select{
    width: 230px !important;
}

.intructorSelect .ant-select-selector{
    height: 45px !important;
    width: 230px !important;

} */
.fileExistsInInstructor{
    color: green;
    font-size: 15px;
}


.instructorCourseSelectionType{
    width: 40%;
    display: flex;
    flex-direction: column;

}

.instructorCourseSelectionTypeGender{
    width: 60%;
}

.instructorCourseSelection{
    width: 100%;
}
.instructorCourseSelection .ant-select{
    width: 100% !important;
}

.instructorCourseSelection .ant-space{
    width: 100% !important;
}

.instructorCourseSelection .ant-space-item{
    width: 100% !important;
}

.addInstructor .ant-spin-spinning{
    position: absolute !important;
    top: 50% !important;
    left: 48% !important;
    z-index: 9999 !important;
    height: 35px !important;
}
