.verifyContainer .ant-btn{
    width: 350px;
    margin-top: 10px;
    font-size: 16px;
    height: 40px;
}

.verifyContainer .ant-btn:hover{
    width: 350px;
    margin-top: 10px;
    color: #fff !important;
    background-color: #2168AE !important;
    height: 40px;
}

.abc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}