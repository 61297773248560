.basicDetails{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.basicDetails .firstName{
    width: 50%;
}


.basicDetails .lastName{
    width: 50%;
}

.selectAdmin{
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 50%;
}

/* .selectAdmin .gender{
    width: 20%;
}

.selectAdmin .age{
    width: 10%;
} */

.adminRegTerms{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.adminRegisterButton .ant-btn{
    width: 100%;
}