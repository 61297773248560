.addCourseFee{
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* float: right; */
}

.basicDetailsCourseFee{
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
}

.firstNameCourseFee{
    width:100% ;
}

.firstNameCourseFee1{
    width:50% ;
}

.firstNameCourseFee .ant-space{
    width: 100% !important;
}

.firstNameCourseFee .ant-space-item{
    width: 100% !important;
}

.firstNameCourseFee .ant-select{
    width: 100% !important;
}

.DueDate .ant-picker{
    height: 45px !important;
    z-index: 1500 !important;
}

.DueDate .ant-picker-dropdown{
    z-index: 1500 !important;
}
.CourseDetailsSubmit{
    margin-top: 8px;
    display: flex;
    flex-direction: row;
}

.firstNameCourseFee .ant-form-item-control-input{
    width: 100% !important;
}

/* .lastNameCourseFee .ant-form-item-control-input{
    width: 100% !important;
} */

.react-datepicker__input-container input {
    height: 40px; /* Adjust the height as needed */
    /* border-radius: 5px; */
    /* border-color: #ccc; */
    width: 100%;
  }

    .react-datepicker__input-container input{
    width: 100% !important;
    border-radius: 5px !important;
   
  }

  .react-datepicker-wrapper{
    width: 100% !important;
    border-radius: 5px !important;
  }

  .react-datepicker__input-container input {
    border-color: #f8f8f8; /* Change the color as needed */
  }