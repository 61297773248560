.addTenantModalForm{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.addTenantBasicDetails{
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.addTenantFooterBtns{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
}

.addTenantField{
    width: 100%;
}

.addTenantField .ant-space{
    width: 100% !important;
}

.addTenantField .ant-space-item{
    width: 100% !important;
}

.addTenantField .ant-select-space{
    width: 100% !important;
}

.addTenantField .ant-select{
    width: 100% !important;
}
.addTenantField .ant-form-item-control-input-content{
    width: 100% !important;
}

.react-datepicker-wrapper input {
    border: 1px solid #d9d9d9; /* Adjust border properties as needed */
    border-radius: 3px; /* Adjust border radius if required */
  }