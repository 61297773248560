.listOfUsers{
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* border: 1px solid lightblue; */
    height: calc(100vh - 100px) !important;
    padding: 25px;
    width: 100%;
    height: 500px;
    max-height: 600px;
    overflow: auto;
}

.allUsers{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    cursor: pointer;
   
}

.dataContent{
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 12px;
}
.chatTimeDetails{
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 8px;
    margin-left: auto;
}
 /* width */
 ::-webkit-scrollbar {
    width: 5px;
 }
/* Track */
 ::-webkit-scrollbar-track {
   background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
   background: #bec4c4;
}

/* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
    background: #555;
}