.singleCourseContainer{
    display: flex;
    flex-direction: row;
}

.coursesFirstColumn{
    width: 60%;
}
.singleCourseImg{
    width: 100%;
}

.sCourseImage{
    width: 100%;
}

.textUnderLine{
    text-decoration: underline;
    cursor: pointer;
}

.ant-breadcrumb a{
    cursor: pointer;
}

.ant-breadcrumb a:hover {
    color: rgba(0, 0, 0, 0.88);
    background-color: none !important;
}

.singleCourseBack{
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.dashboardText{
    color: black;
}