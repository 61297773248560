.batchGrid{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.searchBatch{
    display: flex;
    flex-direction: row;
    margin-left: auto;
}

.showSelection{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.BatchResultContainer{
    display: flex;
    flex-direction: row;
    gap: 250px;
    margin-top: 20px;
}

.batchesTable{
    margin-top: 5px;
}
.studentsSelected{
    height: 400px;
    width: 400px;
    /* border: 2px solid #f3f3f3; */
}

.availableStudents{
    height: 400px;
    width: 400px;
    /* border: 2px solid #f3f3f3; */
}

.text-center{
    text-align: center;
}

.studentsList{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    padding: 10px;
}

.studentRecord{
    height: 200px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
}

.RecordList{
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.ToogleScreenContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.toogleAlignRight{
    margin-left: auto;
    display: flex;
    gap: 8px;
}
.saveBatch{
    width: 60px;
}
.batchSaveBtn{
    margin-top: 24px;
}

.SelectWidth .ant-select{
    width: 200px !important;
}

.batchDate .ant-picker{
    height: 40px !important;
}

.alignBatchSelection{
    display: flex;
    flex-direction: row;
    gap: 6px;
    /* justify-content: center; */
    padding: 2px;
}
.studentbatchSearch{
    padding: 20px;
}
.addStudentBatch{
    float: right;
    margin-top: 15px;
    padding: 8px;
}

.searchBatch .ant-input{
    width: 250px !important;
}

.searchIconBatches{
    bottom: -7px;
    position: relative;
    right: 36px;
}

.borderBatch{
    border-bottom: 1px solid #d9d9d9 !important;
}
.searchBatchFilters{
    border: 1px solid #d9d9d9;
    padding: 12px;
    background-color: #ffff;
}

.SelectWidth .ant-select{
    width: 258px !important;
    height: 45px !important;
}

.batchDate .ant-picker{
    width: 258px !important;
    height: 45px !important;
}

.studentSelectedList{
    display: flex;
    flex-direction: row;
    gap: 15px;
    row-gap: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
}

.cancelimg{
    cursor: pointer;
}

.clearRecords .ant-btn{
    background-color: #ffff !important;
    border: 1px solid #4785c2 !important;
    color: #4785c2;
    text-align: right;
    display: flex;
    flex-direction: row;
    align-items: end;
    margin-left: auto;
}

.clearRecords .ant-btn:hover{
    background-color: #ffff !important;
    border: 1px solid #4785c2 !important;
    color: #4785c2;
    text-align: right;
    display: flex;
    flex-direction: row;
    align-items: end;
    margin-left: auto;
}

.CourseStart .ant-picker{
    height: 45px !important;
}

.weekDaySelect{
    padding: 5px;
}
.weekDaySelect .ant-select-selection-item {
    background: rgba(0, 0, 0, 0.06);
    border: 1px solid transparent;
    padding: 4px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;
}

.ant-select-multiple .ant-select-selection-overflow {
    position: relative;
    display: flex;
    flex: auto;
    flex-wrap: wrap;
    max-width: 100%;
    min-height: 32px;
    max-height: 32px;
    overflow: auto;
    /* padding-bottom: 35px; */
}

.clearAllStudents{
    display: flex;
    margin-left: auto;
    padding: 14px;
}                               
.clearAllStudents .ant-btn:hover{
    color: #4785c2 !important;
}

.RecordListSelected{
    height: 200px;
    overflow: auto;
}


.saveAuthorizeBatch{
    width: 100px;
}