label{
    margin-bottom: 5px !important;
}

  
  .registerForm{
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 6px;
  }

  .regForm{
    display: flex;
    gap: 10px;
    
  }

  .genderContainer{
    display: flex;
    gap: 10px;
    margin-top: 6px;
    /* margin-bottom: 6px; */
  }

  .courseSelection{
    /* margin-top: 6px; */
    margin-bottom: 6px;
  }

  .regInput .ant-input{
    /* margin: 14px; */
    /* margin-right: 5px; */
    /* height: 40px !important; */
  }
  .courseSelect .ant-select{
    width: 600px !important;
}

  .regInput .ant-input{
    width: 296px !important;
}

.input-select-padding {
  padding: 10px;  /* Adjust the padding value as needed */
}



.selectGender{
    display: flex;
    gap: 10px;
    /* padding: 2px; */
    /* margin-right: 15px; */
    /* margin-right: 12px !important; */
}

  .courseSelect.ant-select{
    width: 100% !important;
  }

  .courseSelect.ant-select{
    height: 40px !important;
  }

  .addressSelection{
    /* margin-top: 6px; */
    /* margin-bottom: 18px; */
  }
  
  .addressField .ant-input{
    width: 600px;
    height: 45px;
  }

  .regsiter{
    /* margin-top: 18px;
    margin-bottom: 8px; */
  }

  .others{
    text-align: center;
  }

  .terms{
    
    /* margin-top: 18px;
    margin-bottom: 6px; */
  }

  .sbtns{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
  }

  .socialBtn{

  }

  .loginNow{
    cursor: pointer;
  }
  .loginNow p{
    margin: 0;
  }

  .LoginLink{
    color: black !important;
  }

  .terms{
    /* margin-top: 8px;
    margin-bottom: 8px; */
  }

  /* .rememberMe{
    margin-right: 1px;
  } */

  .star{
    color: red;
  }
   /* .ant-form{
    margin: 1px !important;
    padding: 0 !important;
  } */

  /* .regForm{
    height: 100vh;
    overflow: auto;
  } */
  .others{
    height: 20px;
    margin: 1px;
    padding: 2px;
  }

  .ant-form-label{
    padding: 2px !important;
  }