.allInstructorsHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.instructorSearch{
    margin-left: auto;
    align-items: center;
    width: 250px;
}

.instructorsTable{
    margin-top: 10px;
}
.allInstructors{
    margin-right: 10px;
}

.searchIconInstructors{
    bottom: 78%;
    position: absolute;
    right: 60px;
}


.searchField{
    width: 270px !important;
}


.inActiveClass{
    color: red;
}
.activeClass{
    color: green;
}
.statusActive{
    padding: 20px; 
    border-radius: 8px;
    background-color: #c6fcc5;
    /* height: 15px; */
    width: 100px;
    padding: 3px;
    text-align: center;

}
.dotActive{
    height: 10px;
  width: 10px;
  background-color: #00be1e;
  border-radius: 60%;
  display: inline-block;
  margin-right: 4px;
}
.statusInactive{
    border-radius: 10px;
    background-color: #e1e1e1;
    width: 100px;
    padding: 3px;
    text-align: center;


}

.dotInactive{
    height: 10px;
  width: 10px;
  background-color: #6d6d6d;
  border-radius: 60%;
  display: inline-block;
  margin-right: 4px;
}

/* .defaultSelect .ant-select{
    height: 35px !important;
}

.defaultSelect .ant-select-selector{
    height: 35px !important;
}


.defaultSelect .ant-space{
    height: 35px !important
}

.defaultSelect .ant-space-item{
    height: 35px !important
} */