.resetInput{
    width: 100%;
}

.resetInput .ant-input{
    width: 100% !important;
    padding: 15px;
}

.studentProfileHeader{
    display: flex;
    flex-direction: row;
}

.changePasswordStudent{
    color: #0e60b2 !important;
    cursor: pointer;
}
.studentSettingsContainer{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    /* padding-left: 5px;
    padding-right: 5px; */
    width: 100%;
    padding: 15px;
    /* align-items: center; */
}

.accountSettings{
    margin: 0;
    padding: 0;
}

.accountSettingsText{
    margin-left: 4px;
}
.settingsGrid{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.settingFormItem{
    display: flex;
    flex-direction: row;
    gap: 10px;
    /* width: 50%; */
    flex-wrap: wrap;
    align-items: center;
}

.uploadStudent{
    /* width: 29%; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    /* height: 0; */
    /* margin-left: auto; */
}
.profileInput{
    width: 100%;
}
/* .profileInput .ant-input{
    width: 430px !important;
} */

.profileStep1{
    background-color: #fff;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.profileStep2{
    margin-top: 10px;
    background-color: #fff;
    padding: 18px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.studentPasswordReset{
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.studentPassword .ant-input{
    width: 350px !important;
}

.studentProfileSave{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-left: auto;
    margin-top: 15px;
    gap: 10px;
}

.uploadimageStudent{
    margin-left: auto;
}

.uploadimageStudent .ant-input[type ="file"]{
    /* border: 1px solid !important; */
    padding: 10px;
    height: 95px !important;
    text-align: center;
    display: flex;
    align-items: center;
   
}
/* .uploadimageStudent .ant-upload{
    width: 300px !important;
    height: 150px !important;
} */

.one{
    display: flex;
    flex-direction: column;
}
.oneProfile{
    display: flex;
    flex-direction: row;
    width: 50%;
    gap: 10px;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container {
    /* display: inline-block; */
    width: 10%;
    height: 102px;
    margin-block: 0 8px;
    margin-inline: 0 8px;
    vertical-align: top;
    position: absolute !important;
}

.profilePicture{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}