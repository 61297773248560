.viewSingleBatchContainer{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    width: 100%;
    
}

.goToDashboard{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.backToDashboard{
    display: flex;
    gap: 5px;
    margin-left: auto;
}

.singleBatcContainer{
    display: flex;
    flex-direction: column;
    padding-left: 14px;
    padding-right: 14px;
    background-color: #fff;
    padding: 14px;
}

.classBtns{
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 8px;
}
.headerTrackingClass{
    display: flex;
    flex-direction: row;
}

.dot {
    height: 10px;
    width: 10px;
    background-color: #1677ff;
    border-radius: 50%;
    display: inline-block;
    color: #1677ff;
    display: flex;
    flex-direction: row;
  }

  .dotDisable{
    height: 10px;
    width: 10px;
    background-color: silver;
    border-radius: 50%;
    display: inline-block;
    color: #1677ff;
    display: flex;
    flex-direction: row;
  }
  .daysTracking{
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
  }

  .classesLeft{
    font-size: 12px;
    font-weight: bold;
  }

  /* .sampleRow{
    display: flex;
    flex-direction: row;
  } */
  .batchCourseImage{
    width: 180px;
    height: 150px;
    float: right;
  }

  .singleBatcContainer{
    display: flex;
    flex-direction: row;
  }
  .sampleRow{
    display: flex;
    flex-direction: column;
    /* background-color: #fff;
    padding: 10px; */
  }
  .secondRow{
    margin-left: auto;
  }

  .daysCompleted{
    width: 150px;
    display: flex;
    align-items: center;
  }

  .batchTable{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 6px;
    background-color: #fff;
    padding: 12px;
  }

  .studentTable{
    width: 100%;
    border: 1px solid #f9f9f9;
  }

  .commentTable{
    width: 100%;
    border: 1px solid #f9f9f9;

  }

  .timelineScheduleMeetings{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .timelineScheduleMeetings2{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 10px;
    margin-top: 6px;
  }

  .scheduleimage{
    margin-right: 10px;
  }

  .classTracking{
    background-color: #fff;
    padding: 12px;
  }