.adminLoginContainer{
    background-color: #2168ae;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    height: 100vh;
}

.adminLoginForm{
    display: flex;
    flex-direction: column;
}

.adminLoginForm{
    background-color: #fff;
    border-radius: 15px;
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 15px;
}

.adminLoginBanner{
    margin-right: 5%;
}

.adminLogo{
    display: flex;
    align-items: center;
    justify-content: center;
}

.adminInputField.ant-input-password{
    border: none;
}

.loginAdmin{
    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;
}

.ant-input-affix-wrapper >input.ant-input {
    border: none !important;
}

.admiLoginWithOthers{
    margin: 0;
}
.adminRegister{
    cursor: pointer;
}