.announcementAddContainer{
    display: flex;
    flex-direction: column;
}

.addAnnouncementBtn{
    margin-top: 10px;
}

/* .courseName {
    width: 100%;
} */
.courseName .ant-select-in-form-item{
    width: 100% !important;
}

.courseName .ant-select-in-form-item {
    width: 100% !important;
}

.addAnnouncementBtn{
    display: flex;
    flex-direction: row;
}
