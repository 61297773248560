.ant-input  {
    /* border: none; */
    outline: none !important;
    /* border: 1px solid #e2e2e2 !important; */
 outline: none !important;
 height: 45px;
 /* cursor: pointer; */
}

.ant-input:focus(){
    /* outline: none !important; */
    border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ant-input:hover {
  border-color: none;
  /* cursor: pointer; */
}