.ant-layout .ant-layout-header {
    height: 64px;
    /* padding-inline: 50px; */
    color: rgba(0, 0, 0, 0.88);
    line-height: 64px;
    background: #ffffff !important;
}

.ant-layout .ant-layout-header.darkAlgorithm{
    background-color: #141414 !important;
}

.ant-menu-horizontal {
    /* line-height: 46px; */
    border: none !important;
    /* border-bottom: 1px solid rgba(5, 5, 5, 0.06); */
    /* box-shadow: none; */
}
.ant-layout-header {
    height: 85px !important;
    padding-inline: 50px;
    color: rgba(0, 0, 0, 0.88);
    line-height: 64px;
    background: #ffffff;
    margin-bottom: 12px;
}
.dashboardLogo{
    width: 250px;
    padding: 10px;
}

.header{
    display: flex;
    flex-direction: row;
    padding: 0 !important;
    align-items: center;
}

.headerEnd{
    margin-left: auto;
    align-items: center;
    padding: 10px;
}

.userProfileIcon{
    height: 50px;
}

.messageIcon{
    margin-right: 10px;
}

.bellIcon{
    margin-right: 10px;
}

.profileIcon{
    margin-left: 10px;
}



.ant-layout .ant-layout-header {
   
    padding-left: 2px;
    padding-right: 2px;
}

.ant-layout-content{
    min-height: 550px !important;
    max-height: 750px !important;
    overflow: scroll;

    padding: 10px !important;
    margin: 0px;
    /* background: none !important; */
    border-radius: 8px;
    /* background-color: #ffffff !important; */
    background-color: #f5f5f5 !important;
}


@media only screen and (width: 1536px) {
    .ant-layout-content{
        max-height: 550px !important;
        min-height: 450px !important;
        overflow: scroll;
    
        padding: 10px !important;
        margin: 0px;
        border-radius: 8px;
    }
    
}

@media only screen and (width: 1364px) {
    .ant-layout-content{
        min-height: 550px !important;
        max-height: 800px !important;
        overflow: scroll;
    
        padding: 10px !important;
        margin: 0px;
        border-radius: 8px;
    }
    
}

@media only screen and (width: 1920px) {
    .ant-layout-content{
        min-height: 550px !important;
        max-height: 800px !important;
        overflow: scroll;
        padding: 10px !important;
        margin: 0px;
        /* background: #ffffff !important; */
        border-radius: 8px;
    }
    
}

@media only screen and (width: 1366px) {
    .ant-layout-content{
        min-height: 250px !important;
        max-height: 300px !important;
        overflow: scroll;
    
        padding: 10px !important;
        margin: 0px;
        /* background: #ffffff !important; */
        border-radius: 8px;
    }
    
}


@media only screen and (width: 1440px) {
    .ant-layout-content{
        min-height: 350px !important;
        max-height: 700px !important;
        overflow: scroll;
    
        padding: 10px !important;
        margin: 0px;
        /* background: #ffffff !important; */
        border-radius: 8px;
    }
    
}

@media only screen and (width: 2025px) {
    .ant-layout-content{
        min-height: 200px !important;
        max-height: 250px !important;
        overflow: scroll;
    
        padding: 10px !important;
        margin: 0px;
        /* background: #ffffff !important; */
        border-radius: 8px;
    }
    
}