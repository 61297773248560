.classesTabContainer .ant-tab{
    background-color: #fff;
    padding: 15px;

    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    width: 100%;
}

.searchClassesInfo{
    margin-left: auto;
}

.ant-tabs-nav-list{
    background-color: #fff !important;
    padding: 5px;
}

.classesInfoSearch{
    /* width: 250px;
    display: flex; */
    /* margin-left: auto; */
}
.classesInfoSearch .ant-input{
    margin-left: auto;
    width: 200px;
    margin-bottom: 8px;
}