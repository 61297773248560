.ant-layout .ant-layout-header {
    height: 64px;
    /* padding-inline: 50px; */
    color: rgba(0, 0, 0, 0.88);
    line-height: 64px;
    background: #ffffff;
}



.ant-layout-header {
    height: 85px !important;
    padding-inline: 50px;
    color: rgba(0, 0, 0, 0.88);
    line-height: 64px;
    /* background: #ffffff; */
    margin-bottom: 12px;
}

.header{
    display: flex;
    flex-direction: row;
    padding: 0 !important;
    align-items: center;
}

.dashboardLogo{
    width: 250px;
    padding: 10px;
}

.headerEnd{
    margin-left: auto;
    align-items: center;
    /* padding: 10px; */
}

.userProfileIcon{
    height: 50px;
}

.messageIcon{
    margin-right: 10px;
    cursor: pointer;
}

.messageInformation{
    margin-right: 10px;
    cursor: pointer;
    height: 20px;
    width: 20px;
}

.bellIcon{
    margin-right: 10px;
}

.profileIcon{
    margin-left: 10px;
}

.ant-layout .ant-layout-header {
   
    padding-left: 2px;
    padding-right: 2px;
}

.logout{
    cursor: pointer;
}
.profile{
    cursor: pointer;
}
.hamburger{
    display: flex;
    align-items: center;
}

.hamButton:hover{
    /* background-color: #ffffff !important; */
}
.searchCourses{display: flex; flex-direction: row; gap: 5px; align-items: center;}

.logout{
    cursor: pointer;
}

/* .ant-picker-dropdown{
    z-index: 0 !important;
} */