.addCourseFee{
    /* display: flex;
    flex-direction: row;
    justify-content: end; */
    /* float: right; */
}

.exportIcons1{
    width: 50px;
    cursor: pointer;
    display: flex;
    margin-left: auto;
}

.courseFeeHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.datepickerCustomclass{
    margin-top: 1px;
}
.datepickerCustomclass label{
    font-size: 14px !important;
}
#due_date{
    margin-top: 5px;
    font-size: 14px;
}