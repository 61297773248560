.adminTenant{
    width: 100%;
}

.adminTenant .ant-select{
    width: 100% !important;
}
.adminTenant .ant-input-select{
    width: 100% !important;
}

.adminTenant .ant-form-item-control-input-content{
    width: 100% !important;
}

.adminTenant .ant-space{
    width: 100% !important;
}

.adminTenant .ant-space-item{
    width: 100% !important;
}