.allStudentsHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.studentSearch{
    margin-left: auto;
    align-items: center;
}

.studentsTable{
    margin-top: 10px;
}
.allStudents{
    margin-right: 10px;
}

.searchIconStudents{
    bottom: 2px;
    position: relative;
    right: 35px;
}

.inActiveClass{
    color: green;

    
}
.activeClass{
    color: red;
}
.statusActive{
    /* padding: 20px; */
    border-radius: 10px;
    background-color: #c6fcc5;
    /* height: 15px; */
    width: 100px;
    padding: 5px;
    text-align: center;
}
.dotActive{
    height: 10px;
  width: 10px;
  background-color: #00be1e;
  border-radius: 60%;
  display: inline-block;
  margin-right: 4px;
}
.statusInactive{
    border-radius: 10px;
    background-color: #e1e1e1;
    width: 100px;
    padding: 5px;
    text-align: center;
}

.dotInactive{
    height: 10px;
  width: 10px;
  background-color: #6d6d6d;
  border-radius: 60%;
  display: inline-block;
  margin-right: 4px;
}

.studentSelectAge{
    width: 95px;
}


.hover-effect:hover {
    /* Define the hover effect */
    opacity: 0.7; /* For example, you can change the opacity */
    /* Add any other styles you want to apply on hover */
  }

  .statusInactivePending{
    border-radius: 10px;
    background-color: #f59494;
    width: 100px;
    padding: 5px;
    text-align: center;
}


.dotInactivepending{
    height: 10px;
  width: 10px;
  background-color: #a72e2e;
  border-radius: 60%;
  display: inline-block;
  margin-right: 4px;
}



  
  .menutitle[title]:hover::after {
    content: attr(title);
    position: absolute;
    /* top: 100%; Positioning it below the element */
    left: 0;
    border: none;
    outline: 0px solid transparent;
    color: #fff;
    /* background-color: #fff; Optional: add a background color */
    padding: 5px; /* Optional: add some padding */
    /* border: 1px solid #ccc; Optional: add a border */
    z-index: 10; /* Ensure it appears above other content */
    white-space: nowrap; /* Prevent content from wrapping */
  }
  
  /* .approveClick {
    cursor: pointer;
    color: black;
    transition: color 0.3s ease;
  } */
  
  /* .approveClick:hover {
    color: blue;
  } */
  