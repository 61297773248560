.basicDetailsadminAddCourse{
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 10px;
    padding: 12px;
}

.courseSelectionDetail{
    display: flex;
    flex-direction: row;
    gap: 15px;
}
label{
    margin-bottom: 2px !important;
}

/* .basicCategory{
    width: 100% !important;
} */

.basicCategory .ant-space{
    width: 100% !important;
}

.basicCategory .ant-select{
    width: 50% !important;
}
.basicCategory .ant-space-item{
    width: 100% !important;
}

.basicSelect{
    width: 100% !important;
}

.basicSelect .ant-select{
    width: 100% !important;
}

.basicSelect .ant-select-selector{
    width: 100% !important;
}

.basicSelect .ant-space{
    width: 100% !important;
}
.basicSelect .ant-space-item{
    width: 100% !important;
}

