.individualChat{
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
  width: 100%;
  border: 1px solid #f0f2f5;
  padding: 10px;
  /* align-items: center; */
  /* height: 450px; */
}

.headerProfile{
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.headerProfileDetails{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
}  

hr {
  /* display: block; */
  height: 1px;
  border: 0;
  border-top: 1px solid #f0f2f5;
  margin: 1em 0;
      /* padding: 0; */
}

.chatContent{
  height: 400px;
  max-height: 450px;
  overflow: auto;
}

.sendChatContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}


/* .tick{
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
} */

.chatSendBtn{
  /* margin-left: auto; */
  padding: 15px;
}

.typeMessage{
  width: 100%;
}

.typeMessage .ant-input{
  /* width: 500px; */
  width: 100%;
}

.listOfNames{
  display: flex;
  flex-direction: row;
  gap: 5px;
  color: #66779f;
  cursor: pointer;
}

.modalUsersSearch{
  padding-right: 18px;
  position: static;
  /* top: 0;
  left: 0; */
  /* width: 100%; */
  background-color: #fff;
  padding: 10px;
  z-index: 1000; /* Ensure it's above other content */
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}
.modalMembers{
  height: 300px;
  overflow: auto;
}
.groupNamesInModal{
  margin: 5px;
  display: flex;
  flex-direction: row;
}


.dateContainer{
  width: 100px;
  margin: 0 auto; /* Center the container horizontally */
  text-align: center; 
}
