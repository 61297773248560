.ant-select{
    /* width: 100% !important; */
    height: 40px !important;
}

.ant-select-selector {
    /* width: 100%; */
    height: 40px !important;
    padding: 0 11px;
}
.ant-select-selection-item{
    line-height: 35px !important ;
}