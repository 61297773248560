.courseRecords{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    /* width: 450px; */
    border: 1px solid #d9d9d9;
    padding: 8px;
    background-color: #ffffff;
    gap: 15px;
    
}
.record{
    /* margin-left: 10px; */
    font-weight: bold;
    cursor: pointer;
    font-size: 13px;
}

.exportCourseIcons{
    margin-left: auto;
}

.bulkActions{
    margin-left: 8px;
}

.bulkActions .ant-select{
    width: 150px !important;
}

.courseType{
     color: #8bc6fe;
     background-color: #ffffff;
}