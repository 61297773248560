.ant-layout-sider .darkAlgorithm{
    background: rgb(255, 255, 255);
    flex: 0 0 220px;
    max-width: 220px;
    min-width: 220px;
    width: 220px;
    background-color: #141414;
}
.menuIcon{
    margin-right: 10px;
}
.menutitle[title]::after{
    border: none;
    outline: 0px solid transparent;

    
}
.menutitle[title]:hover::after {
    content: attr(title);
    position: absolute;
    top: -80%;
    left: 0;
    border: none;
    outline: 0px solid transparent;

  }

  /* aside.ant-layout-sider.ant-layout-sider-dark{
    background: rgb(255, 255, 255);
    flex: 0 0 220px;
    max-width: 220px;
    min-width: 220px;
    width: 220px;
    display: flex;
    justify-content: center;
    margin-left: 10px;
  } */


  .menuItemContent {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    /* justify-content: center; */
    width: 100%;
  }

  .menuText {
    margin-bottom: 50px;
    padding-bottom: 25px; /* Adjust if additional padding is needed */
  }

  .menuText.active {
    font-weight: bold;
  }

  .menuIcon {
    filter: grayscale(100%); /* Default to grayscale */
  }
  
  .menuIcon.active {
    filter: none; 
    color: blue;/* Reset filter for active state to show the original color */
  }