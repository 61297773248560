/* .ant-card .cardImage{
    width: 300px;
} */
 .ant-card.coursesCard{
    width: 270px !important;
}
.cardContentContainer{
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.subContent{
    display: flex;
    flex-direction: row;
}

.ant-card .ant-card-body{
    padding: 4px;
}

.cardData{
    font-size: 11px;
    
}

.Info{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    font-size: 10px;
}
.listOfStudentCourses{
    display: flex;
    flex-direction: row;
    gap: 25px;
    /* height: 550px; */
    /* max-height: 500px; */
    overflow: auto;
    flex-wrap: wrap;
}

.alignSpinner{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 50vh;
}