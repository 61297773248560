.notificationContainer{
    height: 220px;
    overflow: auto;
}

.notificationSenderName{
    font-size: 10px;
}

.notificationBody{
    font-size: 12px;
    margin: 0;
}

.notificationBody {
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Limit the text to two lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 200px; /* Set the maximum width to 200 pixels */
  }
  

  .notificationDot {
    position: absolute;
    top: 5px; /* Adjust the top position */
    right: 5px; /* Adjust the right position */
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
  }
  

