.actionIconsReschedule{
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    justify-content: space-between;

}
.statusClick{
    display: flex;
    flex-direction: row;
    /* gap: 6px; */
    align-items: center;
    /* margin-left: 18px; */
    /* width: 50px; */
    margin-left: auto;

}

.dotPopUp{
    height: 6px;
    width: 6px;
    background-color: #6d6d6d;
    border-radius: 60%;
    display: inline-block;
    margin-right: 4px;
    cursor: pointer;
}

.approveReScheduele{
    cursor: pointer;
}

.approveReScheduele{
    display: flex;
    flex-direction: row;
    background-color: #c6fcc5;
    height: 20px;
    padding: 12px;
    align-items: center;
    border-radius: 6px;
}

.rejectReScheduele{
    display: flex;
    flex-direction: row;
    background-color: #ffd0cd;
    height: 20px;
    padding: 12px;
    align-items: center;
    border-radius: 6px;

}

.pedingSchedule{
    display: flex;
    flex-direction: row;
    background-color: #eeeeee;
    height: 20px;
    padding: 12px;
    align-items: center;
    border-radius: 6px;
}
.approveReScheduele::before{
    content: "";
    height: 6px;
    width: 6px;
    background-color: #00be1e;
    border-radius: 60%;
    display: inline-block;
    margin-right: 3px;
    cursor: pointer;
}

.rejectReScheduele::before{
    content: "";
    height: 6px;
    width: 6px;
    background-color: #ef0000;
    border-radius: 60%;
    display: inline-block;
    margin-right: 3px;
    cursor: pointer;
}

.pedingSchedule::before{
    content: "";
    height: 6px;
    width: 6px;
    background-color: black;
    border-radius: 60%;
    display: inline-block;
    margin-right: 3px;
    cursor: pointer;
}

.approveClick{
    cursor: pointer;
}

.allStudentsHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
}


.container {
    display: flex;
    justify-content: flex-end; /* Align items to the end (right side) of the container */
  }
  
  .searchCourse {
    display: flex; /* Use flexbox to align search input and icon horizontally */
    align-items: center; /* Center items vertically */
    margin-right: 20px; /* Optional margin for spacing */
  }
  
  .searchField {
    margin-right: 10px; /* Optional margin between input field and icon */
  }
  