/* custom-tabs.css */
.custom-tabs .ant-tabs-nav {
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  }
  
  .custom-tabs .ant-tabs-content-holder {
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    padding: 10px; /* Add padding if needed */
  }
  
  .custom-tabs .ant-tabs {
    margin: 10px;
    border-radius: 50px;
  }
  

  .showSelection{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}