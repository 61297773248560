
.tenantEnquiryContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.tenantLogoContainer{
    padding: 50px;
    background-color: #fff;
    border-radius: 15px;
}

.tenantEnquiryText{
    text-align: center;
    margin: 2px;
}

.enquirySumitBtn{
    margin-top: 8px;
}