.App {
  background-color: #2168AE;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: auto;
}

.bannerImage{
  height: 500px;
}

.logo{
  display: flex;
  align-items: center;
  
}

.login{
  /* background-color: #fff; */
  height: 100vh;
  /* background-size: cover; */
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom-left-radius: 26px;
    border-top-left-radius: 26px;
    z-index: 1;
}

.formLogo{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* height: 80px; */
}

.buttonGroup{
  display: flex;
  flex-direction: row;
  background-color: #f4f4f4;
  border-radius: 24px;
  justify-content: space-between;
  height: 35px;
  align-items: center;
  padding: 5px;
  gap: 4px;
}

.studentForm.enable{
  background-color: #2168AE;
  color: #f4f4f4;
}

.instructorForm.enable{
  background-color: #2168AE;
  color: #f4f4f4;

}

.underLine{
  text-decoration: underline;
}

.fields{
  /* margin-left: 10%;
  margin-right: 10%; */
}

 .inputField{
  width: 100%;
 border: 1px solid #e2e2e2 !important;
 outline: none !important;
}


.rememberMe{
  margin-left: 4px;
}
.inputPassword{
  width: 100%;
}

.eyeIcon{
  position: absolute;
  right: 85px;
}
svg.p-icon{
  position: absolute;
  left: 575px;
}


.btnColor{
  background-color: #2168AE;
  border-radius: 12px;
}

.switch{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.switchField{
  margin-right: 10px;

}

.forgotpassword{
  margin-left: auto;
}

.btnLogin{
  width: 100%;
  color: #ffff !important;
  background-color: #4785c2 !important;
  border: none;
  font-size: 16px;
}

.loginWithOthers{
  line-height: 1px;
}
.textCenter{
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialIcons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 20px;
  margin-bottom: 25px;
}
.facebookIcon{
  margin-right: 25px;
  /* background-color: #f4f4f4; */
  /* padding: 5px; */
  border-radius: 5px;

}

.googleIcon{
  /* background-color: #f4f4f4; */
  /* padding: 5px; */
  border-radius: 10px;
}

.registerLink{
  line-height: 1px;
  cursor: pointer;
}

.regNow{
  color: black !important;
}

.formLabel{
  margin-bottom: 5px;
  color: #333333;
}
