.adminDashoardContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
}


.totalNumberOfRecords{
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    gap: 10px;
    /* height: ; */
}


.adminNumberOfstudent{
    background-color: #fff;
    width: 35%;
    height: fit-content;
    padding: 15px;
    display: flex;
    flex-direction: row;
    gap: 25px;
}

.adminRowSecond{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.adminDashboardSecondRow{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 70%;
    gap: 12px;
}

.adminSingleCard{
    width: 48%;
    background-color: #fff;
    padding: 15px;
}

.adminDashboardCaledar{
    width: 30%;
}

.admindashboardDatePicker{
    height: 320px;
    background-color: #fff;
    position: relative;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 0.3rem;
    background-color: #fff !important;
}
.adminDashboardNewCourseContent{
    width: 100%;
}

/* .adminDashboardCaledar .ant-picker{
    width: 0 !important;
    height: 0 !important;
    position: initial;
}
.adminDashboardCaledar .ant-picker .ant-picker-input{
    display: none;
}
.adminDashboardCaledar .dashboardDatepicker .ant-picker{
    height: 350px !important;
} */

.admindashboardDatePicker .react-datepicker__input-container input{
    display: none !important;
    
}

.admindashboardDatePicker .react-datepicker-popper{
    position: absolute !important;
    left: 0px !important;
    top: 0px !important;
    /* transform: translate(1200px, 600px) !important; */
    /* transform: translateY(-50px); */
    transform: translateX(55px) !important;

}


.admindashboardDatePicker .react-datepicker{
    border: none !important;
}

.admindashboardDatePicker .react-datepicker__month-container {
    padding: 10px !important;
}

.courseProgressAdmin{
    height: 350px;
    overflow: auto;
}
.gridHeaderAdmin{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}


.headerVedicMath{
    width: 250px;
}

.dotActiveAdmin{
    height: 10px;
  width: 10px;
  background-color: #c6fcc5;
  border-radius: 60%;
  display: inline-block;
  margin-right: 4px;
}

.dotInactiveAdmin{
    height: 10px;
    width: 10px;
    background-color: #e1e1e1;
    border-radius: 60%;
    display: inline-block;
    margin-right: 4px;
}

.dotInactiveAdminPending{
    height: 10px;
    width: 10px;
    background-color: #f59494;
    border-radius: 60%;
    display: inline-block;
    margin-right: 4px;
}

.chartLearnersCount{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}

.doNutAdmin{
display: flex;
flex-direction: row;
justify-content: center;
}

.doNutAdmin canvas{
    width: 300px !important;
    height: 250px !important;
}
.bb1{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.todoContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.addTodoIcon{
    cursor: pointer;
    margin-top: 10px;
}

.adminTodoRecords{
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-top: 10px;
}

.adminTodoCheckBox{
    /* margin-right: 10px; */
}

.todoList{
    height: 250px;
    overflow: auto;
}

.todoDescription{
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    /* align-items: center; */
}


.highlighted-date {
    background-color: red !important;
    color: white !important;
    border-radius: 50%;
}
