.publishCourse{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 12px;
}
.message{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.welcomeTextArea{
    width: 450px !important;
}

.labelName{
    margin-bottom: 4px !important;
}

.filterInstructor{
    width: 100% !important;
}

.filterInstructor .ant-select{
    width: 100% !important;
}

.filterInstructor .ant-select-selector{
    width: 100% !important;
}

.filterInstructor .ant-space{
    width: 100% !important;
}

.filterInstructor .ant-space-item{
    width: 100% !important;
}