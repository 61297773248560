.instructorDashboardContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 15px;
}

.firstDashboardRow{
    display: flex;
    flex-direction: column;
    width: 70%;
}

.instructorDashboardRowSecond{
    margin-top: 18%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.recentActivities{}

.secondDashboardRow{
    display: flex;
    flex-direction: column;
    width: 30%;
}
