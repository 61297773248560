.stuDashboardContainer{
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.firstRow{
    display: flex;
    flex-direction: row;
    width: 75%;
    flex-wrap: wrap;
    gap: 12px;
}
.secondRow{
    width: 20%;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

 /* .ant-picker{
    display: none;
} */
.imageCarousel{
    /* height: 340px; */
    width: 100%;
    /* margin-bottom: 22%; */
    background-color: #fff;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
}
.ant-card .ant-card-body {
    padding: 0px;
}


.dashboardRoundButton{
    display: flex;
    flex-direction: row;
    padding: 20px; 
     /* background-color: #fff; */
}

/* .llm{
    background-color: #fff;
    display: flex;
    flex-direction: row;
    color: black;
    gap: 50px;
    padding: 5px;
    border-radius: 10px;
} */

.btnDataleft{
    margin-right: auto;
    /* display: flex;
    flex-direction: row; */
}
.btnDataRight{
    margin-left: auto;
}

.grid{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
}

.contentPara{
    font-size: 12px;
}
.grid1{
    margin-right: auto;
    width: 50%;
    background-color: #fff;
    padding: 10px;
}
.grid2{
    margin-left: auto;
    width: 50%;
    background-color: #fff;
    padding: 10px;
    height: 310px;
    overflow: auto;
}

.gridHeader{
    display: flex;
    flex-direction: row;
}
.gridContent{
    display: flex;
    flex-direction: row;
    width: 80%;
    gap: 12px;
    flex-wrap: wrap;
}

.gridContent1{
    display: flex;
    flex-direction: column;
}

.gridBody{
    width: 80%;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
}
.smallImg{
    height: 30px;
}
.gridContentImg{
    display: flex;
    flex-direction: row;
}

.gridFooter{
    display: flex;
    flex-direction: row;
}
.reschedule .btnReschedule{
    background-color: #1294f2;
    padding: 8px;
    color: #fff;
    display: flex;
    align-items: center;
}
.rescheduleCancel{
    float: right;
}


.upcomingClassesText{
    color: #1b0a67;
}

.upcomingClasses{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    border: 1px solid #e3e4e5;
    border-radius: 12px;
    padding: 4px;
    margin-bottom: 4px;
}

.viewBtnStudent{
    background-color: #ff7438;
    color: #fff;
   
}


/* .ant-picker-dropdown{
    top: 100px !important;
} */

.dtCalendar{
    height: 300px;
    max-height: 380px;
    overflow: auto;
}

.ant-picker-dropdown {
    z-index: -1; /* Adjust the z-index as needed */
  }
 
  .ant-picker-dropdown {
    position: fixed; /* or position: fixed; */
  }
.dashboardDatePicker{
    height: 320px;
}
.ant-picker-dropdown {
    z-index: 9999; /* Adjust the z-index as needed */
  }