.addCourse{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* gap: 15px; */
}
.addCourseSelect {
    width: 100%;
}
.addCourseSelect .ant-select{
    width: 100% !important;
}

.addCourseSelect .ant-space{
    width: 100% !important;
}

.addCourseSelect .ant-space-item{
    width: 100% !important;
}

.addCourseSelect .ant-select{
    width: 100% !important;
}

.btnS .ant-btn-primary{
    padding: 10px !important;
}

.addformbtn{
    display: flex;
    flex-direction: row;
}

.cancel.ant-btn-Default{
    padding: 1px;
    margin-right: auto;
    width: 200px;
    line-height: 10px;
}

.submit.ant-btn-primary{
    padding: 1px;
    width: 200px;
    line-height: 10px;
}

.text-danger{
    color: red;
}