.App {
    /* background-color: #2168AE;
    height: 100vh;
    display: flex; */
    /* flex-direction: row; */
    /* justify-content: space-between; */
  }
  
  .bannerImage{
    height: 500px;
  }
  
  .logo{
    display: flex;
    align-items: center;
  }
  
  .login{
    background-color: #fff;
    /* height: 100vh; */
    /* background-size: cover; */
    /* width: 50%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom-left-radius: 26px;
      border-top-left-radius: 26px;
  }
  
  .formLogo{
    display: flex;
    /* justify-content: center; */
    /* flex-direction: column; */
    align-items: center;
  }
  
  .buttonGroup{
    display: flex;
    flex-direction: row;
    background-color: #f4f4f4;
    border-radius: 24px;
    justify-content: space-between;
    height: 35px;
    align-items: center;
    padding: 8px;
    gap: 6px;
    width: 220px;
  }
  
  /* .studentForm.enable{
    background-color: #2168AE;
    color: #f4f4f4;
  }
  
  .instructorForm.enable{
    background-color: #2168AE;
    color: #f4f4f4;
  
  }
   */
  .underLine{
    text-decoration: underline;
  }
  
  .fields{
    /* margin-left: 10%;
    margin-right: 10%; */
    padding-left: 10%;
    padding-right: 10%;
  }
  
   .inputField .ant-input{
    /* width: 100%; */
   border: 1px solid #e2e2e2 !important;
   outline: none !important;
   height: 45px !important;
  }
  
  
  .rememberMe{
    margin-left: 4px;
  }
  
  .btnColor{
    background-color: #2168AE;
    border-radius: 12px;
  }
  
  .switch{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .switchField{
    margin-right: 10px;
  
  }
  
  .btnLogin{
    width: 100%;
    color: #ffff !important;
    background-color: #4785c2 !important;
    border: none;
    font-size: 16px;
    padding: 4px;
    height: 40px;
  }
  
  .loginWithOthers{
    line-height: 1px;
  }
  .textCenter{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .socialIcons{
    display: flex;
    flex-direction: row;
    justify-content: center;
  
  }
  .facebookIcon{
    margin-right: 25px;
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 10px;
  
  }
  
  .googleIcon{
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 10px;
  }
  
  .registerLink{
    line-height: 1px;
    cursor: pointer;
  }
  
  .formLabel{
    margin-bottom: 5px;
    color: #333333;
  }
  
  .ant-input-affix-wrapper {
    position: relative;
    display: inline-flex;
    width: 100%;
    min-width: 0;
    padding: 1px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    border-radius: 6px;
    transition: all 0.2s;
}