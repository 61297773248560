.announcementContainer{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.searchAnnouncement{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.announcement{
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.addAnnouncement{
    height: 45px !important;
}

.announcementBtn{
    margin-left: auto;
}

.courseName .ant-space{
    width: 100% !important;
}

.courseName .ant-space-item{
    width: 100% !important;
}

.courseName .ant-select-space{
    width: 100% !important;
}

.courseName .ant-select{
    width: 100% !important;
}
.courseName .ant-form-item-control-input-content{
    width: 100% !important;
}

.announcemenSearchIcon{
    position: absolute;
    /* float: right; */
    /* left: 25px; */
    /* right: 28px; */
    top: 124px;
    left: 95%;
}
