.relatedCoursesContainer{
    display: flex;
    flex-direction: row;
    gap: 10px;
    /* width: 50%; */
    flex-wrap: wrap;
}

.relatedCourses{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    /* justify-content: space-between; */
    flex-wrap: wrap;
}
.relatedCourseHeader{
    display: flex;
    flex-direction: row;
    gap: 10px;
    
}

.relatedCoursesList{
    display: flex;
    flex-direction: row;
    width: 49%;
    /* align-items: center; */
    gap: 10px;
    /* flex-wrap: wrap; */
}

.relatedCourse{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    background-color: #ffff; 
    width: 100%;
    flex-wrap: wrap;
}

.relatedHeader{
    display: flex;
    flex-direction: row;
}

.relatedCourseImage{
    width: 100px;
    height: 100px;
    padding-left: 8px;
}

