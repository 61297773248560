.blankUserChat{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.groupChatIcon{
    width: 150px;
    height: 150px;
}