.messageContainer{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    gap: 25px;
    
}
.usersLists{
    width: 35%;
    /* border: 1px solid lightblue; */
    /* padding: 20px; */
    /* box-shadow: 2px 2px lightgray; */
    z-index: 1;
    background-color: #fff;

}
.singleUserChat{
    width: 60%;
    background-color: #fff;
   
}

.singleUserChat .ant-space .ant-card{
    width: 100%;
}