.AddStudent{
    display: flex;
    flex-direction: column;
    gap: 15px;
    /* padding: 15px; */
}

.studentNames{
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.studentGender{
    display: flex;
    flex-direction: row;
    gap: 15px;
    /* width: 110px !important; */
}

.studentContact{
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.studentInput{
    width: 100%;
}

.dateOfBirth{
    width: 100% !important;
}

.dateOfBirth .ant-select{
    width: 100% !important;
}

.dateOfBirth .ant-space{
    width: 100% !important;
}

.dateOfBirth .ant-space-item{
    width: 100% !important;
}



.formStar{
    color: red;
    width: 25px;
}

.ant-picker{
    width: 100% !important;
    /* height: 40px; */
}

/* .selectDropdownsInstructor{
    display: flex;
    flex-direction: row;
    gap: 11px;
} */

.InstructorInput{
    width: 230px !important;
}
.studentSelectGender .ant-select{
    /* width: 115px !important; */
    height: 45px !important;
}
.intructorSelectAge .ant-select{
    width: 100px !important;
    height: 45px !important;
    display: flex;
    gap: 15px;
}

.studentSelectGender .ant-select .ant-select-selector{
    /* width: 115px !important; */
    height: 45px !important;
}

.intructorSelectAge .ant-select .ant-select-selector{
    /* width: 115px !important; */
    height: 45px !important;
}
 .intructorSelect .ant-select{
    width: 230px !important;
}

.guardianName{
    /* width: 100%; */
}
.ff{
    display: flex;
    gap: 15px;
    flex-direction: row;
}

.studentSelectGender .ant-select{
    width: 115px !important;
    height: 45px !important;
}

 .intructorSelect .ant-select{
    width: 230px !important;
}

.intructorSelect .ant-select-selector{
    height: 45px !important;
    width: 230px !important;

}

.ant-form-vertical .ant-form-item-label >label::after, :where(.css-dev-only-do-not-override-1ae8k9u).ant-col-24.ant-form-item-label >label::after, :where(.css-dev-only-do-not-override-1ae8k9u).ant-col-xl-24.ant-form-item-label >label::after {
    visibility: visible !important;
}

.AddStudent .ant-spin-spinning{
    position: absolute !important;
    top: 50% !important;
    left: 48% !important;
    z-index: 9999 !important;
    height: 35px !important;
}


.SelectWidth .ant-select-selection-placeholder {
    color: #d9d9d9 !important;  /* Light gray color for placeholder */
  }
  
  .placeholder .ant-select-selector {
    color: #d9d9d9 !important;  /* Light gray color for default value */
  }