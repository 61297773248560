.additionalDetails{
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
}
.labelName{
    margin-bottom: 6px;
}


input[type="file"]{
    text-align: left;
    -webkit-rtl-ordering:  left;
    display: flex;
    align-items: center;
    justify-content: center;
 }
 input[type="file"]::-webkit-file-upload-button{
    /* -webkit-appearance: none; */
    float: right;
    /* margin: 0 0 0 10px; */
    border: 1px solid #aaaaaa;
    /* border-radius: 4px; */
    /* background-image: -webkit-gradient(linear, left bottom, left top, from(#d2d0d0), to(#f0f0f0));
    background-image: -moz-linear-gradient(90deg, #d2d0d0 0%, #f0f0f0 100%); */
    
    padding: 10px;
    width: 100px;
    text-align: right;
    /* margin-left: 100px; */
 }

 .fileGrid{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
 }