.coursesCarousel{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

}

.carouselImages{
    width: 215px;
    /* height: 150px; */
}

.carouselText{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.carouselLeft{
    margin-right: 8px;
    cursor: pointer;
}
.courselRight{
    margin-left: 8px;
    cursor: pointer;
}

.ant-card .ant-card-body {
    padding: 0px;
}

 .cardBody{
    background-color: #262626 !important;
    color: #fff !important;
    padding: 0 !important;
    margin-bottom: 2px;
}
.bodyContent{
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #262626 !important;

}
.courseTimings{
    font-size: 12px;
}

.ant-card-bordered .ant-card-cover {
    /* margin-top: -1px; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.courseDetailBtn{
    display: flex;
    /* justify-content: center; */
    flex-direction: row;
    /* padding: 14px; */
    background-color: #fff;
    border-radius: 8px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
   

}

.courseDetailText{
    display: flex;
    flex-direction: row;
    gap: 2px;
    color: #262626;
    font-size: 12px;
    padding-left: 3px;
    padding-right: 3px;
}

.alignSpinnerLiveCourses{
    display: flex;
    justify-content: center;
}

.cardImageLive{
    width: 300px;
    height: 200px;
}
