.allCourses{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
}

.searchCourse{
    margin-left: auto;
    justify-content: center;
    display: flex;
}

.courseTable{
    margin-top: 8px;
}
.allCoursesSelections{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
}

.exportIcons{
    margin-left: 6px;
    margin-right: 6px;
    width: 30px;
    cursor: pointer;
}

.actionIcons{
    display: flex;
    flex-direction: row;
    gap: 5px;
    cursor: pointer;
}
.actionIcons img{
    width: 20px;
}

.searchC .ant-input{
    width: 250px;
}
.searchIcon{
    /* position: relative;
    bottom: 40px;
    padding: 10px;
    left: 200px;
    height: 38px; */

    position: relative;
    /* bottom: -12px; */
    /* padding: 10px; */
    /* left: 43px; */
    height: 20px;
    top: 24px;
    right: 48px;
    
}

.searchC{
    align-items: center;
    display: flex;

}
.addCourseBtn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cancel.ant-btn-Default{
    padding: 1px;
    margin-right: auto;
    width: 200px;
}

.submit.ant-btn-primary{
    padding: 1px;
    width: 200px;
}

.editCourse{
    display: flex;
    flex-direction: row;
}

.alignRight{
    margin-left: auto;
    display: flex;
    flex-direction: row;
}

.stepperSave{
    background-color: #4785c2;
    border: none;
    outline: none;
}

.stepperSave:hover{
    background-color: #4785c2 !important;
    border: none;
    outline: none;
}